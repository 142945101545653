.ContentCardWithImage {
    margin: 0 calc(-1 * var(--wrapperHorizontalPadding));
    overflow: hidden;
    position: relative;

    @media (--tablet) {
        margin: 0;
    }

    &:hover {
        &.Variant--hasLink {
            & .Image {
                opacity: 0.8;
            }
        }
    }
}

.ImageWrapper {
    @mixin aspectRatio 9/16;
    background-color: var(--blackBaseColor);
}

.Image {
    @mixin imageHoverAnimation;
}

.Variant--vertical {
    /* Defaults */
}

.Variant--horizontal {
    @media (--tablet) {
        display: flex;

        & .ImageWrapper {
            lost-column: 1/2 0 0;
        }

        & .Layout {
            lost-column: 1/2 0 0;
        }
    }

    &.Variant--rounded {
        @mixin cornerRounder 12px;
    }
}

.Variant--leftImageAlignment {
    /* Defaults */
}

.Variant--rightImageAlignment {
    & .Layout {
        @media (--tablet) {
            order: -1;
        }
    }
}

.Variant--fillBehavior {
    /* Defaults */
}

.Variant--containBehavior {
    & .ImageWrapper img {
        object-fit: contain;
        object-position: center;
        padding: 24px;
    }
}

.ImageWrapper--transparent {
    &.ImageWrapper.ImageWrapper {
        background-color: transparent;
    }
}

.Variant--stretched {
    @mixin stretchContent;

    & .Layout {
        flex: 1;
    }
}
